import React, { Fragment, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { SiteProvider } from './context/SiteContext';
import { UserProvider } from './context/UserContext';
import { SocketProvider } from './context/SocketContext';
import { WorkerProvider } from './context/WorkerContext';

import Loading from './components/system/Loading';

import DsmOverview from './views/dsm/admin/Overview';

// import Project from './views/mapping/Project';
import ProjectEdit from './views/mapping/components/Edit';
import ProjectTasks from './views/tasks/components/ProjectTasks';
import { DiscussionOutlet } from './views/system/discussion/Discussion';
import FileManager from './views/system/files/FileManagerOutlet';
import Log from './views/system/log/Log';

import Structure from './views/mapping/structure/Structure';
import Quantities from './views/mapping/quantities/Quantities';
import List from './views/mapping/list/List';
import Photos from './views/mapping/photos/Photos';
import ImportTypes from './views/mapping/import/ImportTypes';
import ImportPlots from './views/mapping/import/ImportPlots';
import { LibraryOutlet } from './views/system/library/Library';
import Map from './views/mapping/map/Map';
import Task from './views/tasks/Task';

// survey stuff
import Survey from './views/survey/Survey';
import SurveyList from './views/survey/views/List';
import SurveyDetail from './views/survey/views/Detail';
import SurveyStructure from './views/survey/views/Structure';
import SurveyPlan from './views/survey/views/Plan';

// review stuff
const ReviewDetail  = lazy(() => import('./views/review/views/Detail'));
const ReviewOverview  = lazy(() => import('./views/review/views/Overview'));
const ReviewMap  = lazy(() => import('./views/review/views/Map'));

const Error  = lazy(() => import('./components/system/Error'));
const Logout = lazy(() => import('./views/system/Logout'));

// const Config = lazy(() => import('./views/dsm/Config'));
const Dsm = lazy(() => import('./views/dsm/display/Dsm'));

const Admin = lazy(() => import('./views/admin/Admin'));
const SendEmail = lazy(() => import('./views/system/email/Email'));

const Notices = lazy(() => import('./views/system/notices/Notices'));
const NoticesDisplay = lazy(() => import('./views/system/notices/views/Display'));

const Editor = lazy(() => import('./views/system/editor/Editor'));
const EditorList = lazy(() => import('./views/system/editor/views/List'));
const EditorEdit = lazy(() => import('./views/system/editor/views/Content'));

const Libraries = lazy(() => import('./views/system/library/Libraries'));
const LibraryList = lazy(() => import('./views/system/library/views/List'));

const Cms = lazy(() => import('./views/cms/Cms'));
const Uploads = lazy(() => import('./views/dsm/admin/Uploads'));
const Presentation = lazy(() => import('./views/dsm/admin/presentations/Presentation'));
const Locations = lazy(() => import('./views/dsm/admin/locations/Locations'));
const Analytics = lazy(() => import('./views/dsm/admin/analytics/Analytics'));

const Dashboard = lazy(() => import('./views/Dashboard'));
const Projects = lazy(() => import('./views/mapping/Projects'));
const Tasks = lazy(() => import('./views/tasks/Tasks'));
const Checklists = lazy(() => import('./views/system/checklists/Checklists'));

const Project = lazy(() => import('./views/mapping/Project'));
// const ProjectEdit = lazy(() => import('./views/mapping/components/ProjectEdit'));
// const ProjectTasks = lazy(() => import('./views/tasks/components/ProjectTasks'));
// const Discussion = lazy(() => import('./views/system/discussion/Discussion'));
// const FileManager = lazy(() => import('./views/system/files/FileManager'));
// const Log = lazy(() => import('./views/system/log/Log'));

const Quick = lazy(() => import('./views/quick/Quick'));

const Wayfinder = lazy(() => import('./views/wayfinder/Wayfinder'));

const Share  = lazy(() => import('./views/share/Share'));

// product-specific files
const MapGis = lazy(() => import('./views/mapping/map/MapGis'));
const WGSLogTicket = lazy(() => import('./views/_products/sfo/LogTicket'));

const Schemas = lazy(() => import('./_schemas/ICP'));

const TempImport = lazy(() => import('./views/admin/temp-import/Shimport'));

var hostname = window.location.hostname.replace('www.', '');
if(hostname === 'localhost' || hostname === '192.168.1.67' || hostname === 'wayfindit-v8-staging.herokuapp.com') {
  // hostname = 'admin.mstdsqr.com';
  // hostname = 'device.mstdsqr.com';
  // hostname = 'wgs.flysfo.io';
  hostname = 'new.wayfindit.com';
  // hostname = 'quick.wayfindit.com';
  // hostname = 'review.wayfindit.com';
  // hostname = 'surveyor.wayfindit.com';
  // hostname = 'icp.jblapp.com';
  // hostname = 'inspect.jblapp.com';
}

function App() {

  if(hostname === 'device.mstdsqr.com') {
    return (
      <SiteProvider hostname={hostname}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/:deviceId" element={<Wayfinder />} />
          </Routes>
        </Suspense>
      </SiteProvider>
    );
  }

  if(hostname === 'quick.wayfindit.com') {
    return (
      <SiteProvider hostname={hostname}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/:plotId/:phaseId" element={<Quick />} />
            <Route path="*" element={<Error msg="Wayfindit quick open. Scan a QR code to get started." />} />
          </Routes>
        </Suspense>
      </SiteProvider>
    );
  }

  return (
    <SiteProvider hostname={hostname}>
      <SocketProvider>
        <UserProvider>
          <WorkerProvider>
            <Suspense fallback={<Loading />}>
              <Routes>
                {/*
                  lklklk: you should create a new DSM generic device screen
                  that checks for the right layout and splits between Wayfinder
                  and Related-type content
                */}

                <Route path="/schemas" element={<Schemas />} />
                <Route path="/logout" element={<Logout />} />

                  { (hostname === 'new.wayfindit.com' || hostname === 'emodulex.wayfindit.com' || hostname.includes('jblapp')) && (
                    <Fragment>
                      <Route path="/admin/editor" element={<Editor />}>
                        <Route index element={<EditorList />} />
                        <Route path=":bookId" element={<EditorEdit />} />
                      </Route>
                      <Route path="/admin" element={<Admin />} />
                      <Route path="/this/fucking/sucks/:type/*" element={<TempImport />} />
                      <Route path="/libraries" element={<Libraries />}>
                        <Route index element={<LibraryList />} />
                        <Route path="library/:libraryId" element={<LibraryOutlet parentType="global" />} />
                        <Route path="library/:libraryId/import" element={<ImportTypes parentType="global" />} />
                      </Route>
                      <Route path="/checklists" element={<Checklists />} />
                      <Route path="/projects" element={<Projects />} />
                      <Route path="/project/:projectId" element={<Project />}>
                        <Route path="phase/:phaseId" element={<Structure />} />
                        <Route path="phase/:phaseId/quantities" element={<Quantities />} />
                        <Route path="phase/:phaseId/list" element={<List />} />
                        <Route path="phase/:phaseId/photos" element={<Photos />} />
                        <Route path="phase/:phaseId/import" element={<ImportPlots />} />
                        <Route path="phase/:phaseId/map/:planId" element={<Map />} />
                        <Route path="tasks/:taskId/map/:planId" element={<Task />} />
                        <Route path="tasks/:taskId/:mode" element={<Task />} />
                        <Route path="tasks/:taskId" element={<Task />} />
                        <Route path="tasks" element={<ProjectTasks />} />
                        <Route path="library" parentType="project" element={<LibraryOutlet />} />
                        <Route path="library/import" element={<ImportTypes parentType="project" />} />
                        <Route path="discussion" element={<DiscussionOutlet />} />
                        <Route path="attachments" element={<FileManager parentType="project" />} />
                        <Route path="activity" element={<Log />} />
                        <Route path="settings" element={<ProjectEdit />} />
                      </Route>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path="/content/:bookId/*" element={<Cms />} />
                    </Fragment>
                  )}

                  { hostname === 'wgs.flysfo.io' && (
                    <Fragment>
                      <Route path="/admin/editor" element={<Editor />}>
                        <Route index element={<EditorList />} />
                        <Route path=":bookId" element={<EditorEdit />} />
                      </Route>
                      <Route path="/admin/notifications/:noticeId" element={<Notices />} />
                      <Route path="/admin/notifications" element={<Notices />} />
                      <Route path="/admin/email" element={<SendEmail />}/>
                      <Route path="/admin" element={<Admin />} />
                      <Route path="/libraries" element={<Libraries />}>
                        <Route index element={<LibraryList />} />
                        <Route path="library/:libraryId" element={<LibraryOutlet parentType="global" />} />
                        <Route path="library/:libraryId/import" element={<ImportTypes parentType="global" />} />
                      </Route>
                      <Route path="/projects" element={<Projects />} />
                      <Route path="/project/:projectId" element={<Project />}>
                        <Route path="phase/:phaseId" element={<ProjectEdit />} />
                        <Route path="phase/:phaseId/quantities" element={<Quantities />} />
                        <Route path="phase/:phaseId/list" element={<List />} />
                        <Route path="phase/:phaseId/photos" element={<Photos />} />
                        <Route path="tasks/:taskId/map/:planId" element={<Task />} />
                        <Route path="tasks/:taskId/:mode" element={<Task />} />
                        <Route path="tasks/:taskId" element={<Task />} />
                        <Route path="tasks" element={<ProjectTasks />} />
                        <Route path="discussion" element={<DiscussionOutlet />} />
                        <Route path="attachments" element={<FileManager parentType="project" />} />
                        <Route path="activity" element={<Log />} />
                      </Route>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path="/log-ticket" element={<WGSLogTicket />} />
                      <Route path="/assets" element={<MapGis />} />
                      <Route path="/notifications" element={<NoticesDisplay />} />
                      <Route path="/share/:shareKey/:shareAccess" element={<Share />} />
                      {/* may want to add a logout redirect...? so we show the right login button? */}
                      <Route path="/survey" element={<Survey redirect="/survey" />}>
                        <Route index element={<SurveyList />} />
                        <Route path=":taskId" element={<SurveyDetail gis={true} viewServer={true} />}>
                          <Route index element={<SurveyStructure gis={true} />} />
                          <Route path=":planId" element={<SurveyPlan gis={true} />} />
                        </Route>
                      </Route>
                      <Route path="/:bookId/*" element={<Cms />} />
                    </Fragment>
                  )}

                  { hostname === 'admin.mstdsqr.com' && (
                    <Fragment>
                      <Route path="/" element={<Dashboard />}>
                        <Route path="admin" element={<Admin />} />
                        <Route path="analytics" element={<Analytics />} />
                        <Route path="people" element={<Locations type="person" />} />
                        <Route path="places" element={<Locations type="place" />} />
                        <Route path="presentations/:presoId" element={<Presentation />} />
                        <Route path="presentations" element={<DsmOverview />} />
                        <Route path="pdfs" element={<Uploads text="PDF" fileTypes={['application/pdf']} actions={[{text: 'Bookmarks', type: 'edit bookmarks'}]} convert={true} />} />
                        <Route path="videos" element={<Uploads text="Video" fileTypes={['video/mp4']} convert={false} />} />
                        <Route path="images" element={<Uploads text="Image" fileTypes={['image/jpg','image/jpeg','image/gif','image/png']} thumbnails={true} convert={false} />} />
                      </Route>
                      <Route path="/:deviceId" element={<Dsm />} />
                    </Fragment>
                  )}

                  { (hostname === 'device.mstdsqr.com' || hostname === 'eams-v8-staging.herokuapp.com') && (
                    <Fragment>
                      <Route path="/:deviceId" element={<Wayfinder />} />
                    </Fragment>
                  )}

                {/*
                  <Route path="/:deviceId" element={<Dsm />} />
                  
                  <Route path="/config" element={<Config />} />
                  <Route path="/admin" element={<Admin />} />
                */}

                  { (hostname === 'surveyor.wayfindit.com' || hostname === 'emx.wayfindit.com' || hostname === 'inspect.jblapp.com') && (
                    <Fragment>
                      <Route path="/" element={<Survey />}>
                        <Route index element={<SurveyList />} />
                        <Route path="/:taskId" element={<SurveyDetail />}>
                          <Route index element={<SurveyStructure />} />
                          <Route path=":planId" element={<SurveyPlan />} />
                        </Route>
                      </Route>
                    </Fragment>
                  )}

                  {(hostname === 'review.wayfindit.com' || hostname === 'emxreview.wayfindit.com') && (
                    <Fragment>
                      <Route path="/:taskId" element={<ReviewDetail />}>
                        <Route index element={<ReviewOverview />} />
                        <Route path=":planId/:plotId" element={<ReviewMap />} />
                        <Route path=":planId" element={<ReviewMap />} />
                      </Route>
                    </Fragment>
                  )}
                <Route path="/" element={<Dashboard />} />
                <Route path="*" element={<Error msg="404 Not Found" />} />
              </Routes>
            </Suspense>
          </WorkerProvider>
        </UserProvider>
      </SocketProvider>
    </SiteProvider>
  );
}

export default App;
