import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';
import { UserContext } from '../../../../context/UserContext';

import { Map as MapIcon, Pencil } from 'react-bootstrap-icons'
import Generated  from '../../../../components/system/Generated';
import { formObj } from '../../../../utils/schemas';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id', },
    { id: '3', type: 'input', inputType: 'text', field: 'name', },
    { id: '4', type: 'hidden', field: 'status', },
  ]},
]

// future: get photos from Structure
// need to figure out how to filter by ... plot ids and plan?

const View = (props) => {
  const { idx, obj, pieces, length, toParent } = props;
  const navigate = useNavigate();
  const [qty, setQty] = useState(0);
  const [photoQty, setPhotoQty] = useState(0);
  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  useEffect(() => {
    let filtered = pieces.activePlots.filter(x => x.planId===obj.id);
    let ids = filtered.map(x => x.appId);
    let planPlots = pieces.activePhotos.filter(x => ids.includes(x.plotAppId));
    setQty(filtered.length);
    setPhotoQty(planPlots.length);
  }, [obj, pieces.activePlots, pieces.activePhotos]);

  const onSubmit = async (data) => {
    // hardcode label (for other value/label changes)
    data.label = data.name;
    if(toParent) toParent({ type: 'update plan', value: data });
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plans/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plan. Contact support.'); // lklklk
  };

  const fromChild = (data) => {
    const { name, value } = data;
    if(obj[name] !== value)
      handleSubmit(onSubmit)();
  }

  return (
    <div className={`d-flex align-items-center py-2 ${ idx === length - 1 ? '' : 'border-bottom' }`}>
      <button className="btn px-2" onClick={() => navigate(`./map/${obj.appId}`)}  tabIndex="-1">
        <MapIcon size={16} />
      </button>
      { pieces?.projectRole !== 'contributor' && pieces?.projectRole !== 'viewer' && (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
          <Generated id={`plan-row-${obj.appId}`} schema={schema} size="sm" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>
      )}

      {(pieces?.projectRole==='contributor' || pieces?.projectRole==='viewer') && (
        <h5 className="flex-grow-1 pt-2">{obj.name}</h5>
      )}
      <div className="text-muted me-1">
        {qty > 0 && `${qty} plots`}
        {photoQty > 0 && `, ${photoQty} photos`}
        {(pieces?.loadingPlots && qty===0 && photoQty===0) && (
          <div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
      { pieces?.projectRole !== 'contributor' && pieces?.projectRole !== 'viewer' && (
        <button className="btn px-2 mx-1" onClick={() => pieces.setActivePlan(obj)}  tabIndex="-1">
          <Pencil size={16} />
        </button>
      )}
    </div>
  )
}

export default View;
