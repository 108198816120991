import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { checkConditions } from '../../forms/_utils/conditional';
import { deepCopy } from '../../../../utils/functions'

import Col     from './renders/Col';
import Html    from './renders/Html';
import Input   from './renders/Input';
import Options from './renders/Options';

const View = ({ colIdx, colName, avail, siblingCount, objIdx, obj: ogObj, pieces, toParent }) => {
  return useMemo(() => {
    // not including disabled or error messages here since we don't validate
    // will need to include "repeating" when you get that working
    let obj = deepCopy(ogObj);

    let display = obj.conditional ? checkConditions(obj, pieces.watch) : true;
    if(display === 'error') return <div className="bg-danger text-white p-2">Conditional logic error.</div>

    const componentMap = {
      'html': Html,
      'floating-input': Input,
      'floating-textarea': Input,
      'checkbox': Input,
      'checkboxes': Options,
      'button-group': Options,
      'floating-select': Options,
      'select': Options,
      'autosuggest': Options,
      //... other mappings
    };

    const Component = obj.type.includes('col-') ? Col : componentMap[obj.type];

    if (Component) {
      return <Component key={obj.id} display={display} siblingCount={siblingCount} objIdx={objIdx} obj={obj} colIdx={colIdx} colName={colName} avail={avail} pieces={pieces} toParent={toParent} />;
    } else {
      return <div key={obj.id} className="alert alert-danger p-1 text-center small">Not found: {obj.type}</div>;
    }
  }, [colIdx, colName, avail, siblingCount, objIdx, ogObj, pieces, toParent]);
}

export default View;

View.propTypes = {
  colIdx: PropTypes.number.isRequired,
  obj: PropTypes.object.isRequired,
  toParent: PropTypes.func.isRequired,
};