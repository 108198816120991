import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import useNetwork from "../../../hooks/useNetwork";

import dayjs from 'dayjs'
import db from '../../../utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'

import { emptyObj } from '../../../utils/functions';
import { ArrowClockwise } from 'react-bootstrap-icons';

// should show a message if user doesn't belong to any groups
// and if they don't have anything in their tasks...?

const View = () => {
  const navigate = useNavigate();
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  const { online } = useNetwork();
  const { serverCall } = useAxios();

  const tasks = useLiveQuery(() => db.tasks.toArray(), []);
  const checklists = useLiveQuery(() => db.checklists.toArray(), []);
  const phases = useLiveQuery(() => db.phases.toArray(), []);

  const getTasks = useCallback(async () => {
    let res = await serverCall({
      method: 'GET', url: `/system/tasks/user`, eamsAuth0: true,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
    });
    if(res.status!==200 || !res.data) return alert('Error getting tasks. Contact support.'); // lklklk

    // lklklk: need to clear out old ones and/or ones that have been deleted

    // lklklk: replace with bulkUpdate when v4 comes out
    for(const task of res.data) {
      let found = await db.tasks.update(task.appId, task);
      if(found===0) await db.tasks.put(task);
    }
  }, [userDetails, serverCall]);

  useEffect(() => {
    if(emptyObj(userDetails)) return;
    (async () => {
      let lastSync = await db.system.get('lastSync');
      let resync = 60 * 60 * 1000 * 2; // 2 hours
      let needSync = (Date.now() - lastSync?.value) > resync ? true : false;

      // check if we haven't synced and/or lastSync > 2 hours
      if((!lastSync || needSync) && online) {
        getTasks();
      }
    })();
  }, [userDetails, online, getTasks])

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            { tasks?.length === 0 && <p>You have no assigned {config?.terms?.task?.[1] ?? 'tasks'}.</p>}
            { tasks?.filter(x => x.status==='A').map((obj) => {
              let taskType = obj.taskType;
              let title = (obj.projectNames?.length > 0) ? obj.projectNames[0] : '';

              // find the project + phase for easier list view
              let project = obj.parentIds?.find(x => x.type==='project');
              let phase = phases?.find(x => x.projectId===project?.id && x.id===obj.phaseId);
              if(phase) title = `${title}: ${phase.name}`;

              // lklklk replace with this once you make "Name" field optional for tasks
              // let title = obj.name ? `${obj.projectNames[0]} - ${obj.name}` : obj.projectNames[0];

              if(obj.listId) {
                let found = checklists?.find(x => x.id === obj.listId);
                if(found) taskType = found.name;
              }

              return (
                <div key={obj.appId} className="card mb-3 pointer" onClick={() => navigate(`./${obj.appId}/`)}>
                  <div className="card-body d-flex">
                    <div className="flex-grow-1">
                      <h6>{title.toUpperCase()}</h6>
                      <h5 className="card-title">{taskType}</h5>
                      { obj.lastSync && <p className="card-text"><small className="text-muted">Last synced {dayjs(obj.lastSync).format('MM-DD-YY HH:mma')}</small></p> }
                    </div>
                    <div className="d-flex align-items-center">
                      { obj.dueBy  && dayjs(obj.dueBy).format('MMM DD') }
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col text-end">
            <button className="btn btn-outline-dark mb-3" onClick={getTasks}>
              <ArrowClockwise size={16} />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default View;
