import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';

import db from '../../../utils/dexie-provider.js'
import { emptyObj } from '../../../utils/functions';
import { findPhasePlan } from '../../../utils/phases';

import { ChevronLeft } from 'react-bootstrap-icons';
import Map from '../../../components/mapping/MapV2';
import Filters from '../../mapping/plots/components/Filters';
import ChangePlan from '../components/ChangePlan';

// sfo hardcode plans
import { plans as gisPlans } from '../../../components/_products/sfo/plans';
import GisMap from '../../../components/mapping/GisMap';

import { newPlot, rotatePlot, movePlots } from '../../mapping/plots/utils/plots';
// updatePoints was here too, for drawing mode...?

const View = (props) => {
  const { gis } = props;
  const navigate = useNavigate();
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  const { 
    task, 
    publicStatuses, 
    project, 
    activePhase, 
    planGroups, 
    plans, 
    phases,
    phasePlans,
    activePlan, 
    activePlans,
    planRef, 
    libraries,
    types, 
    setPlots, 
    activePlots, 
    activeQtys,
    setActivePlot, 
    mode, 
    setMode, 
    setBounds,
    search,
    setSearch,
    sort,
    setSort,
    filters,
    setFilters
  } = useOutletContext();
  
  const [mapPlan, setMapPlan] = useState({});

  const [selected, setSelected] = useState([]);
  // add zoomTo back in for non-GIS map when list view is implemented
  // const [zoomTo, setZoomTo] = useState({});

  const activePlanRef = useRef({});
  const activePhaseRef = useRef({});

  // useEffect(() => { activePlanRef.current = activePlan }, [activePlan]);
  // useEffect(() => { activePhaseRef.current = activePhase }, [activePhase]);
  
  useEffect(() => {
    if(gis) return;
    if(emptyObj(project) || emptyObj(activePlan) || emptyObj(activePhase)) return;
    if(!phases || phases.length===0 || !phasePlans || phasePlans.length===0) return;

    // checking against our refs so we don't get flickering as activePlan and activePhase update
    if(activePhase === activePhaseRef.current) return;
    if(activePlan === activePlanRef.current) return;
    activePlanRef.current = activePlan;
    activePhaseRef.current = activePhase;

    (async () => {
      let filtered = phasePlans?.filter(x => x.planId === activePlan.id);
      let phasePlan = findPhasePlan(phases, activePhase, filtered);
      if(phasePlan) {
        // send planId, styling, and signed url to the map
        // planId is required so we filter the plots
        setMapPlan({ projectId: project.id, planId: activePlan.id, styling: phasePlan.styling, base64: phasePlan.base64 });
      }
    })();
  }, [gis, activePhase, activePlan, phasePlans, phases, project])

  const fromChild = async (data) => {
    const { type, value, appId } = data;

    if(type==='view plot') {
      // have to pull from localdb because state isn't updated at this point
      // let found = plots.find(x => x.appId === appId && x.phaseId === activePhase.id);
      let found = await db.plots.where({ appId: appId, phaseId: activePhase.id }).first();
      if(found) setActivePlot(found);

    } else if(type==='longpress' || type==='gis survey add') {
      // add new and display modal; do not auto-save new plot since we are in mobile/app
      let plot = newPlot({ typeId: null, project, plans, types, planRef, activePhase, pieces: value, userDetails });
      setActivePlot(plot);

    } else if(type === 'plots moved') {
      let updates = await movePlots({ setPlots, plots: value, userDetails });
      for(const plot of updates.updates) {
        db.plots.update([plot.appId, activePhase.id], { styling: plot.styling, updatedAt: plot.updatedAt, updatedBy: plot.updatedBy, dirty: 1 });
      }
      // note: minor edit so we wait to sync so moves don't override unsynced data changes

    } else if(type === 'rotated') {
      let update = await rotatePlot({ setPlots, plot: value, userDetails });
      db.plots.update([update.appId, activePhase.id], { styling: update.styling, updatedAt: update.updatedAt, updatedBy: update.updatedBy, dirty: 1 });
      // note: minor edit so we wait to sync so moves don't override unsynced data changes
    } else if(type === 'plan change') {
      navigate(`../${value}`);
    // } else {
    //   console.log(data);
    }
  }

  return (
    <Fragment>
      { activePlan.name && <Helmet><title>{activePlan.name} | {task.name} | {project.name} | {config.title}</title></Helmet>}
      {!gis && (
        <div className="position-fixed" style={{top:16, left:16, zIndex: 100}}>
          <button className="btn bg-white border rounded shadow-sm me-2" onClick={() => navigate(`../`)}>
            <ChevronLeft size={20} className="my-1" />
          </button>
          <ChangePlan planGroups={planGroups} plans={activePlans} plots={activePlots} />
        </div>
      )}

      <div className="position-fixed" style={{top:10, right: 74, zIndex: 100}}>
        <Filters mini={true} project={project} publicStatuses={publicStatuses} activeQtys={activeQtys} libraries={libraries} planGroups={planGroups} plans={plans} types={types} search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} sort={sort} setSort={setSort} />
      </div>
      
      <div className="position-fixed" style={{width:'100vw', height:'100vh'}}>
        { !emptyObj(activePlan) && (
          <Fragment>
            { gis && <GisMap publicStatuses={publicStatuses} inSurvey={true} zoom={20} center={[37.61804737323431, -122.38682301426252]} draggable={true} plans={gisPlans} plots={activePlots} setBounds={setBounds} toParent={fromChild} />}
            {!gis && <Map map={mapPlan} publicStatuses={publicStatuses} touch={true} editable={true} mode={mode} setMode={setMode} plots={activePlots} selected={selected} setSelected={setSelected} toParent={fromChild} />}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default View;
