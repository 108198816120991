import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Generated  from '../../../Generated';
import ConfirmBtn from '../../../../buttons/ConfirmBtn';
import DeleteBtn from '../../../../buttons/DeleteBtn';

import Modal from 'react-bootstrap/Modal';
import { nanoid } from 'nanoid';
import { formObj }  from '../../../../../utils/schemas';
import { deepCopy } from '../../../../../utils/functions';
import { Pencil } from 'react-bootstrap-icons';

// next:
// ability to specify if number, email, switch between long text etc.
// add rows for textareas
// future: validation rules?

// conditional: {type: 'OR',conditions:[{ field: 'udf2', value: 'natural gas' },{ field: 'udf2', value: 'diesel-fueled' }]}

// array, index, field?
const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    { id: '9', type: 'repeat', field: 'conditional', prefix: 'conditions', label: 'Conditions',
      newRow: { field: '', value: '' },
      schema: [{ id: 'repeat1', type: 'col-6', label: '4',
        col1: [{ id: 'repeat2', type: 'floating-input', field: 'field', label: 'Field', required: true }], 
        col2: [{ id: 'repeat3', type: 'floating-input', field: 'value', label: 'Value', required: true }], 
      }]
    },
  ]},
]

const View = ({ colIdx, colName, obj, avail, toParent }) => {
  const { control, register, handleSubmit, formState: { errors }, reset, watch, getValues, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const [canCopy, setCanCopy] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const getFields = useCallback((arr) => {
    const fields = new Set();
    const processObj = (obj) => {
      ['col', 'col1', 'col2', 'col3', 'col4'].forEach(key => {
        if (key in obj) {
          getFields(obj[key]).forEach(field => {
            const match = field.match(/(udf\d+|arr\d+)/);
            if (match) fields.add(match[0]);
          });
        }
      });
      if ('field' in obj) {
        const match = obj.field.match(/(udf\d+|arr\d+)/);
        if (match) fields.add(match[0]);
      }
    };
  
    arr.forEach(processObj);
    return [...fields];
  }, []);

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  useEffect(() => {
    // determine if this can be copied
    let used = getFields([obj]);
    let udfsNeeded = used.filter(x => x.includes('udf')).length;
    let arrsNeeded = used.filter(x => x.includes('arr')).length;
    setCanCopy(udfsNeeded <= avail.udfs.length && arrsNeeded <= avail.arrs.length);
  }, [obj, avail, getFields])

  const fromChild = ({ type }) => {
    if(type==='delete confirmed')
      toParent({ type: 'delete section', colIdx, value: obj.id });
    else if(type==='copy confirmed') {
      let tempAvail = {...avail};
      let ogObj = deepCopy(obj);
      let vals = getValues();
      let update = {...ogObj, ...vals};
      update.id = nanoid();

      ['col', 'col1', 'col2', 'col3', 'col4'].forEach(key => {
        if(key in update) {
          for(const item of update[key]) {
            item.id = nanoid();
            if(!('field' in item)) continue;
            if(item.field.includes('udf')) {
              item.field = avail.udfs[0];
              tempAvail.udfs.shift();
            } else if(item.field.includes('arr')) {
              item.field = avail.arrs[0];
              tempAvail.arrs.shift();
            }
          }
        }
      });
      toParent({ type: 'add section', colIdx: colIdx+1, value: update });
      setShow(false);
    }
  }

  const onSubmit = async (data) => {
    if(!toParent) return;
    let value = { label: data.label, inputType: data.inputType, conditional: data.conditional };
    toParent({ type: 'save section', colIdx, id: data.id, value })
    setShow(false);
  };

  return (
    <Fragment>
      <button className="ms-auto mt-2 btn btn-sm btn-outline-dark" onClick={() => setShow(true)}>
        <Pencil size={16} /> Section
      </button>
      <Modal show={show} size="md" onHide={handleClose} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id={obj ? `schema-input-${obj.id}` : `schema-input-${colIdx}`} schema={schema} size="md" pieces={{ register, control, getValues, setValue, watch, errors }} />
          </form>
          <hr />
          <h5>Copy</h5>
          { canCopy && <p className="mb-2">Use the button below and confirm to copy this section and its fields.</p>}
          {!canCopy && <p className="mb-2">There are not enough available fields to copy this section.</p>}

          <ConfirmBtn styles="btn btn-sm" disabled={!canCopy} toParent={fromChild} warning="Are you sure you want to copy this section?" parentMsg="copy confirmed">
            Copy Section
          </ConfirmBtn>

          <hr />
          <h5>Delete</h5>
          <p className="mb-2">Press the button below and confirm to delete this section. This will remove all fields within this section and cannot be undone once saved.</p>
          <DeleteBtn toParent={fromChild} size="16" text="Delete Section" warning="Are you sure?" />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-sm btn-outline-success" type="button" onClick={() => handleSubmit(onSubmit)()}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
