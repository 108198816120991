import React, { Fragment, useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../context/SiteContext';
import { UserContext } from '../../context/UserContext';
import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';

import dayjs from 'dayjs'
import { pickTextColor } from '../../utils/functions';
import { structureSort, typeSort } from '../../utils/reports';
import { schemaObjArr } from '../../utils/schemas';
import { findPhasePlan } from '../../utils/phases';
import { drawMap } from '../mapping/utils/reports';

// future: repeat/duplicate the survey pdfs so they only get those photos?

// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { config } = useContext(SiteContext);
  const { schemas } = useContext(UserContext);
  const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phasePlans, phases, phase, types, typeVars, typeLists, checklist, libraries, symbols, plots, photos, activeQtys, tasks, publicStatuses } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const iconSize = props.iconSize ? props.iconSize : 16;
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  const [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    let filtered = schemas.filter(x => (x.type==='pdf-report' || x.type==='pdf-report-v3') && x.status==='A');
    setPdfs(filtered);
  }, [schemas])

  const download = async (appId) => {
    console.clear();
    setLoading(true);
    let schema = schemas.find(x => x.appId===appId);
    let report = {...schema.schema};

    // report type 1 = table layout
    // report type 2 = full-page layout
    if(report.version===2) {
      let timestamp = dayjs().format(schema.schema.dateFormat ? schema.schema.dateFormat : 'YYYY-MM-DD');
      let name = `${project.name} - ${phase.name} - ${schema.friendlyName} (PDF)`;
      let filename = `${project.name} - ${phase.name} - ${schema.friendlyName} ${timestamp}`;

      let messageSchema;
      if(config.schemaIds && 'plot-message' in config.schemaIds) {
        let found = schemas.find(x => x.id===config.schemaIds['plot-message']);
        messageSchema = found.schema;
      }

      startWorker({ type: 'pdf', pieces: { name, filename, report, schemas, messageSchema, ...props } });
      setLoading(false);

    } else {
      if(report.reportType===2 || report.reportType===3) {
        let content = [];
        report.footer2.left = 'SITE SURVEY REPORT';
  
        // custom sort:
        // Context, Signage, Vehicles, Marketing, Admin and Operations
        // let order = {};
        // let tempFnc = (order, category) => {
        //   let idx = Object.keys(order).length;
        //   let ids = category.ids;
        //   for (var i = 0; i < ids.length; i++) { 
        //     order[ids[i]] = i+idx;
        //   }
        // }
  
        // tempFnc(order, libraries[0].schema[2]);
        // tempFnc(order, libraries[0].schema[3]);
        // tempFnc(order, libraries[0].schema[4]);
        // tempFnc(order, libraries[0].schema[9]);
        // tempFnc(order, libraries[0].schema[10]);
        // tempFnc(order, libraries[0].schema[5]);
        // tempFnc(order, libraries[0].schema[6]);
        // tempFnc(order, libraries[0].schema[7]);
        // tempFnc(order, libraries[0].schema[8]);
        // tempFnc(order, libraries[0].schema[0]);
        // tempFnc(order, libraries[0].schema[1]);
        // plots.sort((a, b) => order[a.typeId] - order[b.typeId]);
  
        report.images  = {};
        report.images.logo = (report.reportType===2) ? 'https://eams-dev.s3.amazonaws.com/devtemp/Modulex-4C.jpg' : 'https://eams-dev.s3.amazonaws.com/devtemp/ba-logo.png';
        report.images.enterprise = 'https://eams-dev.s3.amazonaws.com/devtemp/enterprise-logo.png';

        let reportHeader = (report.reportType===2) ? project.udf1 : project.name;
        let coverPlan = plans.filter(x => x.status==='A');
        let filtered = phasePlans.filter(x => x.planId === coverPlan[0]?.id);
        let coverPhase = findPhasePlan(phases, phase, filtered);
        let coverImg = '';
        if(coverPhase) {
          coverImg = { image: 'coverImg', fit: [625, 410] };
          report.images.coverImg = coverPhase.url;
        }
  
        let cover = {
          layout: 'noBorders',
          table: {
            dontBreakRows:true,
            pageBreak: 'after',
            widths: [384,118,'*',230,100],
            heights: [170,410,120],
            body: [
              [
                // {text: reportHeader, style: 'address'},
                [],
                [],
                [],
                [],
                [
                  {text: 'Prepared by:'},
                  {image: 'logo', width: 100 }
                ]
              ],
              [
                {stack: [
                  {text: project.name, style: 'title'},
                  {text: project.number, style: 'title', margin: [0,25,0,0]},
                  {text: project.udf1, style: 'address', margin: [0,60,0,0]},
                ]},
                '',
                { colSpan: 3, text: '', stxxxack: [coverImg]},
              ],
              [
                // {image: 'enterprise', fit: [280,60] },
                '',
                '',
                '',
                { colSpan: 2, stack: [
                  {margin:[0,30,0,0], style: 'disclaimer1', text: 'These drawings involve confidential proprietary rights of this product and all design, manufacturing, reproductions, use and sale rights regarding the same are expressly reserved. These drawings are submitted under a confidential relationship for a specified purpose and the recipient by accepting this document assumes custody and agrees (a) that this document will not be copied or reproduced in whole or in part, nor its contents reveal in any manner or to any person except to meet the purpose for which it was delivered and (b) that any special features peculiar to this design will not be incorporated into other projects.'},
                  {margin:[0,12,0,0], style: 'disclaimer2', text: 'This document is designed to be printed on 11" x 17" tabloid paper'}
                ]},
                ''
              ]
            ]
          }
        };
        content.push(cover);
  
        for (const [idx, plan] of plans.filter(x => x.status==='A').entries()) {
          let filtered = phasePlans.filter(x => x.planId === plan.id);
          let phasePlan = findPhasePlan(phases, phase, filtered);
          if(!phasePlan) continue;

          let legend = activeQtys.filter(x => x.typeId!=='total').map(x => ([
            { text: `${x.code}-#`, fillColor: x.fillColor, color: x.textColor ?? pickTextColor(x.fillColor), alignment: 'center', margin: [8, 1, 3, 1] },
            { text: `${x.description ? x.description : ''}`}
          ]));
    
          const half = Math.ceil(legend.length / 2);
          const firstHalf = legend.slice(0, half);
          const secondHalf = legend.slice(half);
  
          // custom filter
          let filteredPlots = [...plots].filter(x => x.planId === plan.id);
          if(filteredPlots.length===0) continue;
    
          let image = await drawMap({ phasePlan, publicStatuses, plots: filteredPlots, hideBg: false });
          console.log(`${plan.name} - ${phasePlan.folder}${phasePlan.name} downloaded`);
  
          let sitePlan = {
            layout: 'noBorders',
            table: {
              dontBreakRows:true,
              pageBreak: 'after',
              widths: [384,'*',200,100],
              heights: [120,580],
              body: [
                [
                  {text: reportHeader, style: 'address'},
                  {text: plan.name, style: 'plotName', alignment: 'center'},
                  [],
                  [
                    {text: 'Prepared by:'},
                    {image: 'logo', width: 100 }
                  ]
                ],
                [
                  { colSpan: 2, stack: [
                    { image, fit: [765, 575], alignment: 'center' },
                  ]},
                  '',
                  { colSpan: 2, stack: [
                    { text: legend.length > 0 ? 'Legend' : '', style: 'header'},
                    // { table: { body: legend }, layout: 'noBorders' }
                    {
                      columns: [
                        { table: { body: firstHalf.length > 0 ? firstHalf : [''] }, layout: 'noBorders' },
                        { table: { body: secondHalf.length > 0 ? secondHalf : [''] }, layout: 'noBorders' },
                      ]
                    },
                  ]},
                  ''
                ]
              ]
            }
          };
          content.push(sitePlan);
        }
  
        for(const plot of plots) {
          let found = types.find(x => x.id === plot.typeId);
          let typeCategory = found?.udf9 ? found.udf9 : '';
          let subCategory  = found?.udf10 ? found.udf10 : '';
          let details = found? schemaObjArr(found.schema, plot) : [];
  
          // if(plot.typeId < 150843) {
            details.splice(2, 0, {label: 'Quantity', value: plot.quantity ? plot.quantity : '1', field: 'quantity' });
          // }

          if(report.reportType===3)
            details.push({ label: 'Notes', value: plot.udf15 });
  
          // lklklk custom sort: should add sort direction, fallbacks, type (e.g. localeCompare etc);
          let customSort = []
          if(report.reportType===2)
            customSort = ['Quantity','Height','Width','Illuminated','Damage','Unclean','Fading/Discoloration','Off-Brand'];
          else
            customSort = ['Quantity','Where is the asset?','Which brand is present?','Is there secondary messaging (beyond \"Delta\")?','Is the sign illuminated?','Sign construction','Height','Width'];

          let sortOrder = new Map(customSort.map((v, i) => [v, i]));
          details.sort((a, b) => sortOrder.get(a.label) - sortOrder.get(b.label));
  
          // find images
          let images = photos.filter(x => x.plotAppId === plot.appId && x.status==='A').sort((a, b) => (new Date(a.createdAt) - new Date(b.createdAt)));
          
          for(const img of images) {
            report.images[`img-${img.id}`] = img.url;
          }
  
          let notes = details.find(x => x.label === 'Notes');
          let existing = details.filter(x => x.label !== 'Notes').map(x => ([
            { text: `${x.label}`, borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
            { text: `${x.value ? x.value : ''}`, borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']}
          ]));
  
          existing.unshift([
            { text: 'Asset Type', borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
            { stack: [
              { text: ` ${plot.display.typeLabel} `, background: plot.display.labelFill ?? plot.display.locatorFill, color: plot.display.textFill }
            ], borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
          ]);

          if(report.reportType===3) {
            // existing.unshift([
            //   { text: 'Sub Category:', borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
            //   { text: subCategory, borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
            // ]);
    
            existing.unshift([
              { text: 'Asset Category', borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
              { text: typeCategory, borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
            ]);
          }
  
          let page = {
            layout: 'noBorders',
            table: {
              dontBreakRows:true,
              pageBreak: 'after',
              widths: [384,'*',200,100],
              heights: [120,580],
              body: [
                [
                  {text: reportHeader, style: 'address'},
                  {text: plot.display.textLabel, style: 'plotName', alignment: 'center'},
                  [],
                  [
                    {text: 'Prepared by:'},
                    {image: 'logo', width: 100 }
                  ]
                ],
                [
                  { colSpan: 2, stack: [
                    { text: 'Existing Signage', style: 'regular'},
                    {
                      layout: 'noBorders',
                      table: {
                        body: [
                          [
                            images[0] ? {image: `img-${images[0].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                            images[1] ? {image: `img-${images[1].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                            images[2] ? {image: `img-${images[2].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                          ],
                          [
                            images[3] ? {image: `img-${images[3].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                            images[4] ? {image: `img-${images[4].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                            images[5] ? {image: `img-${images[5].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                          ]
                        ]
                      }
                    }
                  ]},
                  '',
                  { colSpan: 2, stack: [
                    { text: 'Existing', style: 'header'},
                    { table: { widths: [127,'*'], body: existing } },
                    { text: 'Notes', style: 'header', margin: [0, 35, 0, 0]},
                    {
                      table: {
                        widths: [85,'*'],
                        body: [
                          [
                            { text: 'Notes', borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']},
                            { text: notes?.value ? notes.value : '', borderColor: ['#dbdbdb','#dbdbdb','#dbdbdb','#dbdbdb']}
                          ]
                        ]
                      }
                    }
                  ]},
                  ''
                ]
              ]
            }
          };
          content.push(page);

          if(report.reportType===3 && images?.length > 6) {
            // show additional photos
            let N = 8; // number of images
            let additional = images.slice(6, images.length); // 10 should be images.length;
            
            for (let i = 0; i < additional.length; i += N) {
              const group = additional.slice(i, i + N);
              let contactSheet = {
                layout: 'noBorders',
                table: {
                  dontBreakRows:true,
                  pageBreak: 'after',
                  widths: [384,'*',200,100],
                  heights: [120,580],
                  body: [
                    [
                      {text: reportHeader, style: 'address'},
                      {text: plot.display.textLabel, style: 'plotName', alignment: 'center'},
                      [],
                      [
                        {text: 'Prepared by:'},
                        {image: 'logo', width: 100 }
                      ]
                    ],
                    [
                      { colSpan: 4, stack: [
                        { text: 'Existing Signage', style: 'regular'},
                        {
                          layout: 'noBorders',
                          table: {
                            body: [
                              [
                                group[0] ? {image: `img-${group[0].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[1] ? {image: `img-${group[1].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[2] ? {image: `img-${group[2].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[3] ? {image: `img-${group[3].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                              ],
                              [
                                group[4] ? {image: `img-${group[4].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[5] ? {image: `img-${group[5].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[6] ? {image: `img-${group[6].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                                group[7] ? {image: `img-${group[7].id}`, fit: [230, 275], margin: [0, 0, 3, 5] } : { text: '', margin: [0, 0, 3, 5] },
                              ]
                            ]
                          }
                        }
                      ]},
                      '',
                      ''
                    ]
                  ]
                }
              };
              content.push(contactSheet);
            }
          }
        }
  
        report.content = content;
      } else {
        let messageSchema;
        if(config.schemaIds && 'plot-message' in config.schemaIds) {
          let found = schemas.find(x => x.id===config.schemaIds['plot-message']);
          messageSchema = found.schema;
        }
    
        // check if we want to filter photos by task type/id
        let taskIds = [];
        if(schema?.name === 'survey' || schema?.name === 'application') {
          taskIds = tasks?.filter(x => x.taskType===schema.name).map(x => x.id);
        }
    
        // hardcoding right now, whatever
        if(report.footer1) {
          if(report.footer1?.left?.type === 1) {
            let text = project.name;
            if(phases.filter(x => x.status === 'A').length > 1) {
              text = project.name+`\n`+phase.name;
            }
            report.footer1.left = text;
          }
        }
        
        // scaffolding for other content layouts/sorts types
        if(report.content[0]?.table) {
          let data = [];
          let images = [];
          if(report.headers) data.push(report.headers);
          delete report.headers;
    
          if(report?.type === 'types')
            [data, images] = await typeSort({ data, report, messageSchema, types, typeVars, symbols, planGroups, plans, plots, photos, taskIds });
          else if(report?.type === 'quantities') {
            // lklklk: seems like we should be able to filter this the same way...
            // [data] = await structureSort({ report, messageSchema, project, phase, types, typeVars, symbols, planGroups, plans, plots: activeQtys });
            data = activeQtys.map(x => {
              if(x.typeId==='total') 
                return ['',{text: 'Total', alignment: 'right'}, {text: x.quantity, alignment: 'right'}];
              else
                return [{text: x.code, noWrap: true}, {text: x.description, margin: [12, 0]}, {text: x.quantity, alignment: 'right'}];
            });
    
            data.unshift(['Code', {text: 'Description', margin: [12, 0]}, {text: 'Quantity', alignment: 'right'}]);
          } else
            [data, images] = await structureSort({ data, report, messageSchema, schemas, project, types, typeVars, typeLists, checklist, symbols, planGroups, plans, plots, photos, taskIds });
    
          report.content[0].table.body = data;
          report.images = images;
        }
      }
  
      let timestamp = dayjs().format(schema.schema.dateFormat ? schema.schema.dateFormat : 'YYYY-MM-DD');
      let name = `${project.name} - ${phase.name} - ${schema.friendlyName} (PDF)`;
      let filename = `${project.name} - ${phase.name} - ${schema.friendlyName} ${timestamp}`;
  
      if(report.reportType===2) {
        // filename = `Brand Asset Audit Report - ${project.number} - ${project.udf1}`;
        filename = `Site Survey Report - ${project.name}`;
      } 
  
      startWorker({ type: 'pdf', pieces: { name, filename, content: report } });
      setLoading(false);
    }
  }

  if(pdfs.length===0) return null;

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={() => download(pdfs[0].appId)} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={iconSize} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Generating
          </Fragment>
        )}        
      </button>
      { pdfs.length > 1 && (
        <Fragment>
          <button type="button" className={`dropdown-toggle dropdown-toggle-split ${classes}`} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            { pdfs.map(obj => (
              <li key={obj.appId} className="dropdown-item pointer" onClick={() => download(obj.appId)}>{obj.friendlyName}</li>
            ))}

            {/* <li className="dropdown-item pointer">Text Only</li>
            <li className="dropdown-item pointer">Thumbnails</li>
            <li className="dropdown-item pointer">Install Status</li>
            <li className="dropdown-item pointer">Install Photos</li> */}
          </ul>
        </Fragment>
      )}
    </div>
  )
}

export default View;
