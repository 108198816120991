import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Generated  from '../../../Generated';
import ConfirmBtn from '../../../../buttons/ConfirmBtn';
import DeleteBtn from '../../../../buttons/DeleteBtn';

import Modal from 'react-bootstrap/Modal';
import { nanoid } from 'nanoid';
import { formObj }  from '../../../../../utils/schemas';
import { Back as BackIcon, Pencil } from 'react-bootstrap-icons';

// next:
// ability to specify if number, email, switch between long text etc.
// add rows for textareas
// future: validation rules?

// conditional: {type: 'OR',conditions:[{ field: 'udf2', value: 'natural gas' },{ field: 'udf2', value: 'diesel-fueled' }]}

// array, index, field?
const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    { id: '7', type: 'floating-input', field: 'label', placeholder: 'Add a label/prompt here' },
    { id: '8a', type: 'floating-select', label: 'Type', field: 'inputType', options: [
      {value: '', label: 'Text/Default'},
      {value: 'date', label: 'Date'},
      {value: 'datetime-local', label: 'Date/Time'},
      {value: 'number', label: 'Number'},
    ]},
    { id: '9', type: 'repeat', field: 'conditional', prefix: 'conditions', label: 'Conditions',
      newRow: { field: '', value: '' },
      schema: [{ id: 'repeat1', type: 'col-6', label: '4',
        col1: [{ id: 'repeat2', type: 'floating-input', field: 'field', label: 'Field', required: true }], 
        col2: [{ id: 'repeat3', type: 'floating-input', field: 'value', label: 'Value', required: true }], 
      }]
    },
  ]},
]

const View = ({ colIdx, colName, objIdx, obj, avail, toParent }) => {
  const { control, register, handleSubmit, formState: { errors }, reset, watch, getValues, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const addNew = (type) => {
    if(!toParent) return;
    if(type==='long')
      toParent({ type: 'add block', colIdx, colName, value: { id: nanoid(), type: 'floating-textarea', label: 'New long answer', field: avail.udfs[0], rows: 4 }})
    else
      toParent({ type: 'add block', colIdx, colName, value: { id: nanoid(), type: 'floating-input', label: 'New short answer', field: avail.udfs[0] }})
  }

  const fromChild = ({ type }) => {
    if(type==='delete confirmed')
      toParent({ type: 'delete block', colIdx, colName, value: obj?.id });
    else if(type==='copy confirmed') {
      let vals = getValues();
      vals.id = nanoid();
      vals.field = obj?.field?.includes('udf') ? avail.udfs[0] : avail.arrs[0];
      toParent({ type: 'add block', colIdx, colName, objIdx: objIdx+1, value: vals })
    }
  }

  const onSubmit = async (data) => {
    if(!toParent) return;
    let value = { label: data.label, inputType: data.inputType, conditional: data.conditional };
    toParent({ type: 'save block', colIdx, colName, id: data.id, value })
    setShow(false);
  };

  if(!obj) {
    return (
      <Fragment>
        <li onClick={() => addNew('short')} className="dropdown-item pointer">Short Answer</li>
        <li onClick={() => addNew('long')} className="dropdown-item pointer">Long Answer</li>
      </Fragment>  
    )
  }

  return (
    <Fragment>
      <button className="btn btn-sm mt-2 text-muted" onClick={() => setShow(true)}>
        <Pencil size={16} />
      </button>
      {(obj.field.includes('udf') && avail?.udfs.length > 0) && (
        <ConfirmBtn toParent={fromChild} styles="btn-sm" confirmTxt="Confirm Duplicate" parentMsg="copy confirmed">
          <BackIcon size={16} />
        </ConfirmBtn>
      )}
      <Modal show={show} size="md" onHide={handleClose} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Input</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id={obj ? `schema-input-${obj.id}` : `schema-input-${colIdx}`} schema={schema} size="md" pieces={{ register, control, getValues, setValue, watch, errors }} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <DeleteBtn toParent={fromChild} size="16" />
          <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-sm btn-outline-success" type="button" onClick={() => handleSubmit(onSubmit)()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
