import React, { Fragment, useContext, useEffect, useState } from 'react';

import { useAxios } from '../../../../hooks/useAxios';
import { UserContext } from '../../../../context/UserContext';
import { emptyObj } from '../../../../utils/functions';

import Collapsed from '../../../../components/system/Collapsed';
import FormBasic from '../../../../components/system/FormBasic';

import { ArrowsCollapse, ArrowsExpand } from 'react-bootstrap-icons'

const View = (props) => {
  const { obj } = props;
  const { userDetails } = useContext(UserContext);
  const { serverCall } = useAxios();

  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [typeLists, setTypeLists] = useState([]);

  // const [openAll, setOpenAll] = useState(false);
  // const toggleOpen  = () => setOpenAll((s) => !s);

  useEffect(() => {
    if(emptyObj(obj) || emptyObj(userDetails)) return;
    (async () => {
      let res = await serverCall({ method: 'GET', url: `/library/checklists/type/${obj?.appId}`, eamsAuth0: true });
      if(res.status!==200 || !res.data) return alert('Error getting checklists. Contact support.'); // lklklk

      console.log(res.data);
      setLists(res.data.checklists);
      setTypeLists(res.data.typeLists);
      setLoading(false);
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj, userDetails])


  if(loading) {
    return (
      <div className="spinner-border spinner-border-sm d-block my-5 mx-auto" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

  return (
    <Fragment>
      {/* <div className="clearfix">
        <button className="btn btn-outline-dark float-end" onClick={toggleOpen}>
          { openAll && <Fragment><ArrowsCollapse size={18} /> Collapse All</Fragment> }
          {!openAll && <Fragment><ArrowsExpand size={18} /> Expand All</Fragment>}
        </button>
      </div> */}

      { lists.map(list => {
        let typeList = typeLists.find(x => x.typeId === obj?.id && x.checklistId === list.id);
        if(typeList?.schema?.length===0 && list.config?.default?.length > 0) typeList.schema = list.config.default;

        return (
          <div key={list.appId} className="border shadow-sm py-2 px-3 mb-2">
            <Collapsed title={list.name} open={false}>
              <div className="mt-2">
              { typeList?.schema && <FormBasic debug={true} schema={typeList.schema} />}
              </div>
            </Collapsed>
          </div>
        )
      })}
    </Fragment>
  )
}

export default View;
