import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Generated  from '../../../Generated';
import ConfirmBtn from '../../../../buttons/ConfirmBtn';
import DeleteBtn from '../../../../buttons/DeleteBtn';

import Modal from 'react-bootstrap/Modal';
import { nanoid } from 'nanoid';
import { emptyObj } from '../../../../../utils/functions';
import { formObj }  from '../../../../../utils/schemas';
import { Back as BackIcon, Pencil } from 'react-bootstrap-icons';

// lklklk: need to add error messages to the Repeating rows...
// lklklk: need to add "preset" buttons like Yes/No etc.

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id' },
    // { id: '3', type: 'hidden', field: 'array' },
    // { id: '4', type: 'hidden', field: 'index' },
    // { id: '5', type: 'hidden', field: 'field' },
    // { id: '6', type: 'hidden', field: 'conditional' },
    { id: '7', type: 'floating-input', field: 'label', label: 'Add a label/prompt here' },
  ]},
  { id: '4', type: 'col-6', label: '4',
    col1: [{ id: '8a', type: 'floating-select', label: 'Type', field: 'type', optionVar: 'type-opts' }], 
    col2: [{ id: '8f', type: 'floating-select', label: 'Linked Options (optional)', field: 'linked', optionVar: 'variables' }], 
  },
  { id: '6', type: 'col-12', label: '4',
    col: [
      { id: '9', type: 'repeat', label: 'Options', field: 'options', 
        newRow: { value: 'TBD', label: '' },
        schema: [{ id: 'repeat1', type: 'col-6', label: '4',
          col1: [{ id: 'repeat2', type: 'floating-input', field: 'value', label: 'Value', required: 'Required' }], 
          col2: [{ id: 'repeat3', type: 'floating-input', field: 'label', label: 'Label (optional)' }], 
        }]
      },
      { id: '10', type: 'html', content: '<hr />' },
      { id: '11', type: 'repeat', field: 'conditional', prefix: 'conditions', label: 'Conditions',
        newRow: { field: '', value: '' },
        schema: [{ id: 'repeat1a', type: 'col-6', label: '4',
          col1: [{ id: 'repeat2b', type: 'floating-input', field: 'field', label: 'Field', required: true }], 
          col2: [{ id: 'repeat3c', type: 'floating-input', field: 'value', label: 'Value', required: true }], 
        }]
      },
    ]
  },
]

const View = ({ colIdx, colName, objIdx, obj, pieces, avail, toParent }) => {
  const [typeOpts, setTypeOpts] = useState([]);
  const [variables, setVariables] = useState([]);
  const { control, register, handleSubmit, formState: { errors }, reset, watch, getValues, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if(!obj) return;

    // determine the type options
    if(obj?.field?.includes('udf')) {
      setTypeOpts([
        {value: 'button-group', label: 'Toggle Buttons'},
        {value: 'floating-select', label: 'Select (Floating)'},
        {value: 'select', label: 'Select'},
        {value: 'autosuggest', label: 'Autosuggest'},
      ]);
    } else {
      setTypeOpts([{value: 'checkboxes', label: 'Checkboxes'}])
    }

    let filled = formObj(schema, obj);

    // manually adding the linked one now
    // currently only one value, future may do multiple?
    filled.linked = obj.schema?.appId;

    reset(filled);
  }, [obj, reset])

  useEffect(() => {
    if(!pieces) return;
    let variables = pieces?.schemas?.filter(x => x.type==='variable').map(x => ({ value: x.appId, label: x.friendlyName }));
    variables.unshift({ value: null, label: 'None' });
    setVariables(variables);
  }, [pieces])

  const addNew = (type) => {
    if(!toParent) return;
    if(type==='yesno')
      toParent({ type: 'add block', colIdx, colName, value: { id: nanoid(), type: 'button-group', label: 'New Single Choice', field: avail.udfs[0], options: [{value: 'Yes'},{value:'No'}] }})
    else
      toParent({ type: 'add block', colIdx, colName, value: { id: nanoid(), type: 'checkboxes', label: 'New Multiple Choice', field: avail.arrs[0], options: [{value: 'Option A'},{value:'Option B'},{value:'Option C'}] }})
  }

  const fromChild = ({ type }) => {
    if(type==='delete confirmed')
      toParent({ type: 'delete block', colIdx, colName, value: obj?.id });
    else if(type==='copy confirmed') {
      let vals = getValues();
      vals.id = nanoid();
      vals.field = obj?.field?.includes('udf') ? avail.udfs[0] : avail.arrs[0];
      toParent({ type: 'add block', colIdx, colName, objIdx: objIdx+1, value: vals });
      setShow(false);
    }
  }

  const onSubmit = async (data) => {
    if(!toParent) return;
    data.schema = !emptyObj(obj.schema) ? {...obj.schema} : {};
    data.schema.appId = (!data.linked || data.linked==='None') ? null : data.linked;
    let value = { label: data.label, type: data.type, schema: data.schema, options: data.options, conditional: data.conditional };
    toParent({ type: 'save block', colIdx, colName, id: data.id, value });
    setShow(false);
  };

  if(!obj) {
    return (
      <Fragment>
        <li onClick={() => addNew('multi')} className="dropdown-item pointer">Multiple Choice</li>
        <li onClick={() => addNew('yesno')} className="dropdown-item pointer">Yes/No</li>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <button className="btn btn-sm mt-2 text-muted" onClick={() => setShow(true)}>
        <Pencil size={16} />
      </button>
      <Modal show={show} size="md" onHide={handleClose} backdrop={true} keyboard={true} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Checkboxes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Generated id={obj ? `schema-html-${obj.id}` : `schema-input-${colIdx}`} schema={schema} size="md" pieces={{ vars: { variables, 'type-opts': typeOpts }, register, control, getValues, setValue, watch, errors }} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <DeleteBtn toParent={fromChild} size="16" />
          {((obj.field.includes('udf') && avail?.udfs.length > 0) || (obj.field.includes('arr') && avail?.arrs.length > 0)) && (
            <ConfirmBtn toParent={fromChild} styles="btn-sm" confirmTxt="Confirm Duplicate" parentMsg="copy confirmed">
              <BackIcon size={16} />
            </ConfirmBtn>
          )}
          <button className="btn btn-sm btn-outline-dark ms-auto me-1" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-sm btn-outline-success" type="button" onClick={() => handleSubmit(onSubmit)()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default View;
