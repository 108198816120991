import { nl2cols, sumByKey, sumByTwoKeys } from '../utils/functions';
import { getGenerated } from '../components/artwork/_utils/functions';
import { findSchema, schemaObjArr } from './schemas';
import dayjs from 'dayjs';

// future: ability to sort sheets/reports by custom vals?
// will need this for folks who want to do by type instead of location

// color text/bg by status

// error reporting!
// maybe highlight a field red if there should be a value?
// check the error report that Weidner does!

const fieldMap = async({ template, schemas, project, phase, type, types, typeVar, typeVars, typeLists, checklist, symbols, group, plan, photos, taskIds, plot, messageSchema} ) => {
  const getItem = (plot, item) => {
    if(!item) return;
    let arr = [];
    for(const child of item) {
      let msg = null;

      if(child.type === 'html') {
        let html = new DOMParser().parseFromString(child.content, "text/html").querySelectorAll('h1, h2, h3, h4, h5, h6');
        for(const tag of html) {
          arr.push([{ text: tag.innerHTML, style: 'smallBold' } ]);
        }
        continue;
      } else if(child.type==='repeating') {
        // lklklk check repeating here...
        // console.log(child);
        continue;
      } else if(!child.array) {
        msg = plot[child.field];
      } else {
        msg = plot[child.array][child.index];
      }
      if(!msg) continue;

      if(child.label) {
        arr.push([
          { text: child.label, style: 'smallBold' },
          { text: msg, margin: [0, 0, 0, 8] }
        ])
      } else {
        arr.push([
          { text: msg, margin: [0, 0, 0, 8] }
        ])
      }
    }
    return arr;
  }

  const getMsg = (plot, schema) => {
    let arr = [];
    for(const item of schema) {
      if(item.type==='col-12')
        arr = arr.concat(getItem(plot, item.col));
      else if(item.type==='col-6' || item.type==='col-48' || item.type==='col-84') {
        arr = arr.concat(getItem(plot, item.col1));
        arr = arr.concat(getItem(plot, item.col2));
      } else if(item.type==='col-4' || item.type==='col-336' || item.type==='col-363' || item.type==='col-633') {
        arr = arr.concat(getItem(plot, item.col1));
        arr = arr.concat(getItem(plot, item.col2));
        arr = arr.concat(getItem(plot, item.col3));
      } else if(item.type==='col-3') {
        arr = arr.concat(getItem(plot, item.col1));
        arr = arr.concat(getItem(plot, item.col2));
        arr = arr.concat(getItem(plot, item.col3));
        arr = arr.concat(getItem(plot, item.col4));
      }
    }
    return arr;
  }

  // this will probably work but you need to pass more shit
  // const getRow = async (plots, rows) => {
  //   return new Promise( async (resolve)=>{
  //     if(rows?.length===0 || plots?.length===0) return resolve(null);
  //     let data = [];
  //     let allImages = [];

  //     for(const plot of plots) {
  //       let plotData = [];
  //       let type = types.find(x => x.id === plot.typeId);
  //       let typeVar = typeVars.find(x => x.id === plot.variationId);

  //       for(const row of rows) {
  //         let { field, images } = await fieldMap({ template: row, project, phase, group, plan, type, typeVar, types, typeVars, symbols, plan, photos, taskIds, plot, messageSchema });
  //         if(!field || field.length===0) {
  //           plotData.push('');
  //         } else {
  //           plotData.push(field);
  //         }

  //         allImages = {...allImages, ...images};
  //       }
  //       data.push(plotData);
  //     }

  //     resolve(data, allImages);
  //   })
  // }

  const getField = async (parent, template) => {
    return new Promise( async (resolve)=>{
      // should we check if the field includes arr, and if so
      // merge them? otherwise need to figure out how to split
      // and add headers...?
      let value;
      if(template.index!==undefined) {
        value = parent[template.field][template.index];
      } else if(parent && template.field in parent) {
        value = parent[template.field];
      } else if(template.text) {
        value = template.text;
      }

      // handle null value default -- need to check option too??
      if(!value && template.null)
        value = template.null;

      if(template.uppercase) {
        // does this need to handle arrays too?
        value = value?.toUpperCase();
      }

      if(template.splitCols) {
        value = nl2cols([value]);
      }

      if(template.numCols) {
        // lklklk future: the concat below appends to the end of the array
        // would we ever need an instance where we need to pad the beginning?
        value = value.concat(new Array(template.numCols).fill('').map(String)).slice(0, template.numCols);
      }

      if(template.symbol) {
        if(Array.isArray(value)) {
          for (const [idx, id] of value.entries()) {
            let symbol = symbols.find(x => x.id === Number(id));
            value[idx] = symbol ? symbol.name : '';
          }
        } else {
          let symbol = symbols.find(x => x.id === Number(value));
          value = symbol ? symbol.name : '';
        }
      }

      if(template.type===2) {
        // planGroup + plan, or just plan
        let format = group ? group.name+`\n`+plan.name : plan.name;
        resolve({ value: format });

      } else if(template.type===3) {
        // plot quantity
        let qty = plot.quantity ? plot.quantity : 1;
        if(template.multiplier) qty = qty*template.multiplier;
        resolve({ value: qty });

      } else if(template.type===4) {
        // this is how we get the message
        let found = types.find(x => x.id===parent.typeId);
        let val;
        if(found?.schema?.length > 0) {
          val = getMsg(plot, found.schema);
        } else if(messageSchema) {
          val = getMsg(plot, messageSchema);
        }

        resolve({ value: val });

      } else if(template.type===5) {
        // need to do photos here
        let arr = [];
        let images = {};

        let filtered = photos.filter(x => x.plotAppId === plot.appId && taskIds.includes(x.taskId));
        for(const img of filtered) {
          images[`img${img.id}`] = img.url;
          arr.push({ image: `img${img.id}`, width: 150, xxfit: [200,123], margin: [0, 3, 0, 0] });
        }
        
        resolve({ value: arr, images });

      } else if(template.type===6) {
        let type = types.find(x => x.id===plot.typeId);
        let typeVar = typeVars.find(x => x.id===plot.variationId);
        if(!type) return resolve(null);

        // lklklk: may want to refine this instead of clobbering the type
        let display = typeVar ? {...type, ...typeVar} : {...type};

        let arr = [];
        let images = {};

        // determine if/which this type has art to show
        if(display.prevAurl || display.prevBurl) {
          // if preview art exists, show that
          let prevA = await getGenerated(display.prevAurl, display, plot, plot.arr1, symbols);
          arr.push({ image: prevA, fit: [100, 150] })

          if(display.prevBurl) {
            let prevB = await getGenerated(display.prevBAurl, display, plot, plot.arr1, symbols);
            arr.push({ image: prevB, fit: [100, 150], margin: [0, 8, 0, 0] })
          }
        } else if(display.prodAurl || display.prodBurl) {
          // if no preview, show production
          let prodA = await getGenerated(display.prodAurl, display, plot, plot.arr1, symbols);
          arr.push({ image: prodA, fit: [100, 150] })

          if(display.prodBurl) {
            let prodB = await getGenerated(display.prodBurl, display, plot, plot.arr1, symbols);
            arr.push({ image: prodB, fit: [100, 150], margin: [0, 8, 0, 0] })
          }
        } else if(display.thumbAurl || display.thumbBurl) {
          // otherwise show thumbnails
          images[`thumb-${display.id}A`] = display.thumbAurl;
          arr.push({ image: `thumb-${display.id}A`, fit: [100,150] });

          if(display.thumbBurl) {
            images[`thumb-${display.id}B`] = display.thumbBurl;
            arr.push({ image: `thumb-${display.id}B`, fit: [100,150], margin: [0, 8, 0, 0] });
          }
        }

        resolve({ value: arr, images });

      } else if(template.type===7) {
        
        // artwork output/generated filename
        // future: need to distinguish prod vs preview etc?
        // console.log(type, typeVar);
        let art = {...typeVar, ...type};
        if(!art) return resolve(null);

        let filename = `${plot.display.textLabel} - ${project.name}`;
        if(phase?.name !== 'Phase 1') filename = filename + ` ${phase.name}`;
        if(group?.name) filename = filename + ` - ${group.name}`;
        filename = filename + ` Artwork - ${plan.name}`;
        filename = filename + ` Loc ${String(plot.name).padStart(3, '0')}-${String(plot.id).padStart(3, '0')}`;
        filename = filename + ` Qty ${plot.quantity ? plot.quantity : '1'}`;
        filename = filename + ` ${dayjs().format('YYYY-MM-DD')}`;
        if(!template.omitExt) filename = filename + `.svg`;
        resolve({ value: filename });

      } else if(template.type===8) {
        // qr code
        let path = `https://quick.wayfindit.com/${plot.appId}/${phase.appId}`;
        console.log(path);
        let arr = [
          { qr: 'https://google.com', fit: 50, margin: [0, 0, 0, 3]}
        ]
        resolve({ value: arr });
      } else if(template.type===9) {
        resolve({ value: `https://quick.wayfindit.com/${plot.appId}/${phase.appId}` });
      } else if(template.type===10) {
        // plot schema

      } else if(template.type===11) {
        // typeSchema

      } else if(template.type===12) {
        // plot append/bottom schema (?)

      } else if(template.type===13) {
        // listSchema
        console.log('getting task list schema');
        let schema = [];
        let arr;



        // lklklk you are here...
        // try either to it in this one or in a new one
        // need to be able to use a template to show
        // either plot details, all details, etc



        // plotSchema - default system fields from plot-edit + config (append)
        // typeSchema - permanent plot data based on sign type
        // detailSchema - default system fields from plot-edit + config (room name, mounting, etc.)
        // listSchema - checklist + type combo or config/default
        




        let plotSchema = [];
        let found = schemas.filter(x => x.name==='plot-edit');
        found = findSchema(found, project); // this one could have multiple schemas
        if(found?.schema) plotSchema = found.schema;

        if(plot.typeId) {
          // check if a typeList combo exists for this typeId/checklistId
          let typeList = typeLists.find(x => x.typeId===plot.typeId && x.checklistId===checklist.id);
          if(typeList) schema = typeList.schema;
    
          // no list schema, look for default in config
          
          if(schema.length===0 && 'default' in checklist.config && checklist.config.default?.length > 0) {
            schema = checklist.config.default;
          }
        }
        console.log(plotSchema);
        console.log(schema);

        // if(!typeId) return setListSchema([]);
        // let newListSchema = [];
    

    
        // setListSchema(newListSchema);




        resolve({ value: arr });
        

      } else if(template.label) {
        if(template.optional && !value) resolve(null);
        let arr = [
          { text: template.label, style: template.labelStyle },
          { text: value, margin: [0, 0, 0, 8]}
        ]
        resolve({ value: arr });
      } else {
        if(template.optional && !value) resolve(null);
        resolve({ value });
      }
    });
  }

  let typeField = {};
  if(template.parent === 'type') {
    let found = types.find(x => x.id === plot.typeId);
    if(found) typeField = found;
  } else if(template.parent === 'typeVar') {
    let found = typeVars.find(x => x.id === plot.variationId);
    if(found) typeField = found;
  }

  let fields = template.fields ? template.fields : [template];
  let arr = [];
  let images = {};
  for(const item of fields) {
    let val;
    if('value' in item) {
      arr.push(item.value);
      continue;
    }

    switch (item.parent) {
      case 'project': 
        val = await getField(project, item);
        break;
      case 'phase': 
        val = await getField(phase, item);
        break;
      case 'group': 
        val = await getField(group, item);
        break;
      case 'plan': 
        val = await getField(plan, item);
        break;
      case 'type': 
        val = await getField(typeField, item);
        break;
      case 'typeVar': 
        val = await getField(typeField, item);
        break;
      case 'plot.display': 
        val = await getField(plot.display, item);
        break;
      default: 
        val = await getField(plot, item);
        break;
    }

    if(Array.isArray(val?.value))
      arr = arr.concat(val.value);
    else if(val?.value) 
      arr.push(val.value);
    if(val?.images) images = {...images, ...val.images};
  }

  if(template.numCols) {
    // lklklk future: the concat below appends to the end of the array
    // would we ever need an instance where we need to pad the beginning?
    arr = arr.concat(new Array(template.numCols).fill('').map(String)).slice(0, template.numCols);
  }

  // remove unnecessary array wrapper if length = 1
  if(arr.length === 1)
    arr = arr[0];

  return { field: arr, images };
}

export const structureSort = async ({ data, report, messageSchema, schemas, project, phase, types, typeVars, typeLists, checklist, symbols, planGroups, plans, plots, photos, taskIds }) => {
  if(report.rows?.length===0) return;
  if(!data) data = [];
  let allImages = {};

  if(report.typeIds?.length > 0)
    plots = plots.filter(x => report.typeIds.includes(x.typeId));
  if(report.variationIds?.length > 0)
    plots = plots.filter(x => report.variationIds.includes(x.variationId));

  let groups = planGroups.filter(x => x.status==='A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
  for(const group of groups) {
    let groupPlans = plans.filter(x => x.planGroupId===group.id && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for(const plan of groupPlans) {
      let planPlots = plots.filter(x => x.planId === plan.id);
      for(const plot of planPlots) {
        let type = types.find(x => x.id === plot.typeId);
        let typeVar = typeVars.find(x => x.id === plot.variationId);
        
        if(report.type==='summary') {
          let details = type ? schemaObjArr(type.schema, plot) : [];
          details.splice(2, 0, {label: 'Quantity', value: plot.quantity ? plot.quantity : '1', field: 'quantity' });

          // lklklk custom sort: should add sort direction, fallbacks, type (e.g. localeCompare etc);
          let customSort = ['Quantity','Height','Width','Illuminated','Damage','Unclean','Fading/Discoloration','Off-Brand'];
          let sortOrder = new Map(customSort.map((v, i) => [v, i]));
          details.sort((a, b) => sortOrder.get(a.label) - sortOrder.get(b.label));

          for(const detail of details) {
            // don't include if it's blank
            if(!plot[detail.field] || !detail.value) continue;
            let plotData = [];
            let rows = [...report.rows];

            rows.splice(7, 0, { value: detail.label });
            rows.splice(8, 0, { field: detail.field, parent: 'plot' });

            for(const row of rows) {
              let { field, images } = await fieldMap({ template: row, schemas, project, phase, group, plan, type, typeVar, types, typeVars, typeLists, checklist, symbols, photos, taskIds, plot, messageSchema });
              if(!field || field.length===0)
                plotData.push('');
              else if(field.length > 1 && !row.merge)
                plotData = plotData.concat(field);
              else
                plotData.push(field);
      
              allImages = {...allImages, ...images};
            }
            data.push(plotData);
          }
  
        } else {
          let plotData = [];
          for(const row of report.rows) {
            let { field, images } = await fieldMap({ template: row, schemas, project, phase, group, plan, type, typeVar, types, typeVars, typeLists, checklist, symbols, photos, taskIds, plot, messageSchema });
            if(!field || field.length===0)
              plotData.push('');
            else if(field.length > 1 && !row.merge)
              plotData = plotData.concat(field);
            else
              plotData.push(field);
    
            allImages = {...allImages, ...images};
          }
          data.push(plotData);
        }
      }
    }
  }

  let soloPlans = plans.filter(x => !x.planGroupId && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
  for(const plan of soloPlans) {
    let planPlots = plots.filter(x => x.planId === plan.id);
    for(const plot of planPlots) {
      let type = types.find(x => x.id === plot.typeId);
      let typeVar = typeVars.find(x => x.id === plot.variationId);
      
      if(report.type==='summary') {
        let details = type ? schemaObjArr(type.schema, plot) : [];
        details.splice(2, 0, {label: 'Quantity', value: plot.quantity ? plot.quantity : '1', field: 'quantity' });

        // lklklk custom sort: should add sort direction, fallbacks, type (e.g. localeCompare etc);
        let customSort = ['Quantity','Height','Width','Illuminated','Damage','Unclean','Fading/Discoloration','Off-Brand'];
        let sortOrder = new Map(customSort.map((v, i) => [v, i]));
        details.sort((a, b) => sortOrder.get(a.label) - sortOrder.get(b.label));

        for(const detail of details) {
          // don't include if it's blank
          if(!plot[detail.field] || !detail.value) continue;
          let plotData = [];
          let rows = [...report.rows];
          rows.splice(7, 0, { value: detail.label });
          rows.splice(8, 0, { field: detail.field, parent: 'plot' });

          for(const row of rows) {
            let { field, images } = await fieldMap({ template: row, schemas, project, phase, plan, type, typeVar, types, typeVars, typeLists, checklist, symbols, photos, taskIds, plot, messageSchema });
            if(!field || field.length===0)
              plotData.push('');
            else if(field.length > 1 && !row.merge)
              plotData = plotData.concat(field);
            else
              plotData.push(field);
    
            allImages = {...allImages, ...images};
          }
          data.push(plotData);
        }

      } else {
        let plotData = [];
        for(const row of report.rows) {
          let { field, images } = await fieldMap({ template: row, schemas, project, phase, plan, type, typeVar, types, typeVars, typeLists, checklist, symbols, photos, taskIds, plot, messageSchema });
          if(!field || field.length===0)
            plotData.push('');
          else if(field.length > 1 && !row.merge)
            plotData = plotData.concat(field);
          else
            plotData.push(field);

          allImages = {...allImages, ...images};
        }
        data.push(plotData);
      }
    }
  }

  return [data, allImages];
}

export const typeSort = async ({ data, report, messageSchema, project, phase, types, typeVars, symbols, planGroups, plans, plots, photos, taskIds }) => {
  if(report.rows?.length===0) return;
  if(!data) data = [];
  let allImages = {};

  if(report.typeIds?.length > 0) {
    types = types.filter(x => report.typeIds.includes(x.id));
    plots = plots.filter(x => report.typeIds.includes(x.typeId));
  }
    
  if(report.variationIds?.length > 0)
    plots = plots.filter(x => report.variationIds.includes(x.variationId));

  for(const type of types) {
    let typePlots = plots.filter(x => x.typeId===type.id);
    if(typePlots.length===0) continue;
    let groups = planGroups.filter(x => x.status==='A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for(const group of groups) {
      let groupPlans = plans.filter(x => x.planGroupId===group.id && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
      for(const plan of groupPlans) {
        let planPlots = typePlots.filter(x => x.planId === plan.id);
        for(const plot of planPlots) {
          let plotData = [];
          let type = types.find(x => x.id === plot.typeId);
          let typeVar = typeVars.find(x => x.id === plot.variationId);
  
          for(const row of report.rows) {
            let { field, images } = await fieldMap({ template: row, project, phase, group, plan, type, typeVar, types, typeVars, symbols, photos, taskIds, plot, messageSchema });
            if(!field || field.length===0)
              plotData.push('');
            else if(field.length > 1 && !row.merge)
              plotData = plotData.concat(field);
            else
              plotData.push(field);
  
            allImages = {...allImages, ...images};
          }
          data.push(plotData);
        }
      }
    }

  let soloPlans = plans.filter(x => !x.planGroupId && x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
  for(const plan of soloPlans) {
    let planPlots = typePlots.filter(x => x.planId === plan.id);
    for(const plot of planPlots) {
      let plotData = [];
      let type = types.find(x => x.id === plot.typeId);
      let typeVar = typeVars.find(x => x.id === plot.variationId);

      for(const row of report.rows) {
        let { field, images } = await fieldMap({ template: row, project, phase, type, typeVar, types, typeVars, symbols, plan, photos, plot, taskIds, messageSchema });
        if(!field || field.length===0)
          plotData.push('');
        else if(field.length > 1 && !row.merge)
          plotData = plotData.concat(field);
        else
          plotData.push(field);

        allImages = {...allImages, ...images};
      }
      data.push(plotData);
      }
    }
  }

  return [data, allImages];
}

export const formatHeaders = async ({ report, plots }) => {
  let headers = [...report.headers];
  for(const header of headers) {
    // should this total calculation be put up in the field map?
    // or would this always be only in the header?
    if(header.value==='total') {
      let arr = [...plots];
      let typeIdLength = report.typeIds?.length ? report.typeIds.length : 0;
      let varIdLength  = report.variationIds?.length ? report.variationIds.length : 0;
      
      if(header.conditions) {
        for(const condition of header.conditions) {
          if(condition.typeIds?.length > 0)
            arr = arr.filter(x => condition.typeIds.includes(x.typeId));

          if(condition.variationIds?.length > 0)
            arr = arr.filter(x => condition.variationIds.includes(x.variationId));

          if(condition.field)
            arr = arr.filter(x => x[condition.field] === condition.value);
        }
      }

      let included = [];
      if(typeIdLength === 0) {
        // not splitting by types or vars
        included = sumByKey(arr, 'typeId');
      } else if(typeIdLength > 0 && varIdLength === 0) {
        // splitting by types only, not variations
        let qtys = sumByKey(arr, 'typeId');
        included = qtys.filter(x => report.typeIds.includes(x.typeId));
      } else {
        // splitting by types and variations
        let qtys = sumByTwoKeys(arr,'typeId','variationId');
        included = qtys.filter(x => report.variationIds.includes(x.variationId));
      }

      let totals = included.map(x => x.quantity);
      let sum = totals.reduce((partialSum, a) => partialSum + a, 0);
      if(header.multiplier) sum = sum*header.multiplier;
      header.header = sum;
    }
  }
  return report.headers;
}