import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';

import db from '../../../utils/dexie-provider.js'
import { useLiveQuery } from 'dexie-react-hooks'

import Loading from '../../../components/system/Loading';

import Modal from 'react-bootstrap/Modal';
import PlotEdit from '../components/Plot';

import { emptyObj } from '../../../utils/functions';
import { findSchema } from '../../../utils/schemas';
import { applyFilters } from '../../mapping/plots/components/Filters';
import { typeQtys } from '../../mapping/plots/utils/plots';
import formatPlots from '../../mapping/plots/utils/format';


// sfo hardcode plans -- leaving this in survey for now
// eventually will want to move into config so they are auto-loaded
import { plans as gisPlans } from '../../../components/_products/sfo/plans';

// lklklk future: collapse building... groups plan groups
// future: task detail should allow to select specific groups/plans
// future: right now this grabs all plots of the project... should it ... not?

// should show a message if user doesn't belong to any groups
// and if they don't have anything in their tasks...?

const View = (props) => {
  const { gis, viewServer } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { dirtyQty } = useOutletContext();
  const { online, config } = useContext(SiteContext);
  const { userDetails, schemas, checklists, typeChecklists } = useContext(UserContext);
  const { isLoading, isAuthenticated } = useAuth0();
  const { serverCall } = useAxios();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});
  const [activePhase, setActivePhase] = useState({});
  const [activePlan, setActivePlan] = useState({});
  const planRef = useRef({});

  const [activePlans, setActivePlans] = useState([]);
  const [plots, setPlots] = useState([]);
  const [activePlot, setActivePlot] = useState({});
  // right now this toggles, so just setting to true/false
  // future you may want to do different modes like has photo
  const [mode, setMode] = useState(false);

  const task = useLiveQuery(() => db.tasks.where({appId: params.taskId}).first());
  const phases = useLiveQuery(() => db.phases.where({projectId: project.id ? project.id : 0}).toArray(), [project]);
  const planGroups = useLiveQuery(() => db.planGroups.where({projectId: project.id ? project.id : 0, status: 'A'}).toArray(), [project]);
  const plans = useLiveQuery(() => db.plans.where({projectId: project.id ? project.id : 0, status: 'A'}).toArray(), [project]);
  const plotTasks = useLiveQuery(() => db.plotTasks.where({taskId: task?.id ? task.id : 0}).toArray(), [task]);

  // phasePlans aren't indexed by projectId at the moment,
  // so just grabbing all of them and filtering within structure
  const phasePlans = useLiveQuery(() => db.phasePlans.toArray(), []);

  const allLibraries = useLiveQuery(() => db.libraries.toArray());
  const [libraries, setLibraries] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [types, setTypes] = useState([]);
  const [typeVars, setTypeVars] = useState([]);
  const [publicStatuses, setPublicStatuses] = useState([]);

  const [checklist, setChecklist] = useState({});
  const [typeLists, setTypeLists] = useState([]);

  const [bounds, setBounds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState();
  const formattedPlots = useMemo(() => formatPlots(project, plans, types, typeVars, plots), [project, plans, types, typeVars, plots]);
  const activePlots  = useMemo(() => applyFilters(activePhase, activePlan, planGroups, activePlans, formattedPlots, filters, search, bounds, false, 'plan', types, typeVars), [activePhase, activePlan, planGroups, activePlans, formattedPlots, filters, search, bounds, types, typeVars]);
  const activeQtys  = useMemo(() => typeQtys(types, typeVars, activePlots), [types, typeVars, activePlots]);

  const handleClose = () => setActivePlot({});

  useEffect(() => {
    if(!isLoading && !isAuthenticated)
      navigate('/');
  }, [isLoading, isAuthenticated, navigate])

  const getTask = useCallback(async () => {
    if(!online) return;
    if(dirtyQty > 0) {
      return alert('You have unsynced items.\nPlease sync or clear your local data to re-sync/download.');
    }

    setLoading(true);
    let details = await serverCall({
      method: 'GET', url: `/system/tasks/survey/${params.taskId}`, eamsAuth0: true,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
    });
    if(details.status!==200 || !details.data) return alert('Error getting task project/plots. Contact support.'); // lklklk

    await db.projects.put(details.data.project);
    await db.plotTasks.bulkPut(details.data.plotTasks);

    let res = await serverCall({
      method: 'GET', url: `/mapping/projects/${details.data?.project?.appId}`, eamsAuth0: true,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
    });
    if(res.status!==200 || !res.data) return alert('Error getting project details. Contact support.'); // lklklk
    await db.phases.bulkPut(res.data.phases);
    await db.planGroups.bulkPut(res.data.planGroups);
    await db.symbols.bulkPut(res.data.symbols);
    await db.types.bulkPut(res.data.types);
    await db.variations.bulkPut(res.data.typeVars);

    let res2 = await serverCall({
      method: "GET", url: `/mapping/projects/${details.data?.project?.appId}/plots`, eamsAuth0: true,
      headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.appId }
    });
    if(res2.status!==200 || !res2.data) return alert('Error getting project plots. Contact support.'); // lklklk
    if(res2.data?.plots) await db.plots.bulkPut(res2.data.plots);
    // lklklk this one ^ also sends back photos; could use this to identify server photo quantities

    // lklklk: replace plans and phasePlans below with bulkUpdate when v4 comes out
    // currently looping through so we don't clobber any base64 downloads
    for(const plan of res.data.plans) {
      let found = await db.plans.update(plan.appId, plan);
      if(found===0) await db.plans.put(plan);
    }

    for(const phasePlan of res.data.phasePlans) {
      let found = await db.phasePlans.update(phasePlan.id, phasePlan);
      if(found===0) await db.phasePlans.put(phasePlan);
    }

    await db.tasks.update(params.taskId, { lastSync: Date.now() });

    let task = await db.tasks.get(params.taskId);
    let phase = res.data.phases.find(x => x.id === task.phaseId);
    setActivePhase(phase);
    setProject(details.data.project);
    setLoading(false);
  }, [userDetails, params.taskId, dirtyQty, online, serverCall]);

  useEffect(() => {
    if(emptyObj(userDetails)) return;
    (async () => {
      let task = await db.tasks.get(params.taskId);
      if(!task) return navigate('/');

      let lastSync = task.lastSync;
      let resync = 60 * 60 * 1000 * 12; // 12 hours
      let needSync = (Date.now() - lastSync) > resync ? true : false;

      // check if we haven't synced and/or lastSync > 12 hours
      if((!lastSync || needSync) && online && dirtyQty===0) {
        console.log('not synced and nothing dirty, get task info');
        getTask();
      } else {
        // find projectId
        let parentIds = task?.parentIds?.filter(x => x.type === 'project');
        if(!task || parentIds.length === 0) return alert('Error finding project. Resync data.');

        let projectId = parentIds[0].id;
        if(!projectId) return alert('Error finding project. Resync data.');
        let project = await db.projects.where({id: projectId}).first();
        let phase = await db.phases.where({id: task.phaseId}).first();
        setProject(project);
        setActivePhase(phase);
        setLoading(false);
      }
    })();
  }, [params.taskId, userDetails, getTask, navigate, dirtyQty, online])

  useEffect(() => {
    if(emptyObj(project) || emptyObj(checklist)) return;
    (async () => {
      console.clear();
      console.log(project);

      // get all asset types and symbols
      let typeIds = [];
      let symbolIds = [];
      for (const library of project.library){
        // will we need to also loop for project typeIds linked symbols?
        typeIds = [...new Set([...typeIds ,...library.ids])]
      }

      let libraries = allLibraries.filter(x => project.linked?.includes(x.id));
      setLibraries(libraries);

      for(const library of libraries) {
        for (const list of library.schema) {
          typeIds = [...new Set([...typeIds ,...list.ids])];
        }
  
        symbolIds = [...new Set([...symbolIds ,...library.linked])];
      }

      let symbols = await db.symbols.toArray();
      let types = await db.types.toArray();
      let typeVars = await db.variations.toArray();
      symbols = symbols.filter(x => symbolIds.includes(x.id));
      types = types.filter(x => typeIds.includes(x.id)).sort((a, b) => ((a || {}).label || '').localeCompare((b || {}).label || '', undefined, { numeric: true, sensitivity: 'base' }));
      typeVars = typeVars.filter(x => typeIds.includes(x.typeId)).sort((a, b) => ((a || {}).label || '').localeCompare((b || {}).label || '', undefined, { numeric: true, sensitivity: 'base' }));
      setSymbols(symbols);
      setTypes(types);
      setTypeVars(typeVars);

      let plots = await db.plots.where({projectId: project.id ? project.id : 0}).toArray();

      if(checklist.config?.types) {      
        if(checklist.config.types.include) plots = plots.filter(x =>  checklist.config.types.include.includes(x.typeId));
        if(checklist.config.types.exclude) plots = plots.filter(x => !checklist.config.types.exclude.includes(x.typeId));
      }
      setPlots(plots);

      // lklklk probably do the same for types here

      // need to do it for statuses...
      // note that this one in particular will change by company
      // and maybe even by project type???
      // let found = await db.schemas.where({name: 'plot-actions'}).first();
      // if(found) setActions(found.schema);

    })();
  }, [project, checklist, allLibraries])

  useEffect(() => {
    let checklist = checklists?.find(x => x.id === task?.listId);
    if(!checklist || !gisPlans || !plans) return;

    let activePlans = gis ? [...gisPlans] : [...plans];
    if(checklist.config?.plans) {      
      if(checklist.config.plans.include) activePlans = activePlans.filter(x =>  checklist.config.plans.include.includes(x.id));
      if(checklist.config.plans.exclude) activePlans = activePlans.filter(x => !checklist.config.plans.exclude.includes(x.id));
    }
    setActivePlans(activePlans);

    let typeLists = typeChecklists?.filter(x => x.checklistId === checklist.id && x.status==='A');
    setChecklist(checklist);
    setTypeLists(typeLists);
  }, [task, checklists, typeChecklists, gis, plans])

  useEffect(() => {
    let schema = [];
    let found = schemas.filter(x => x.name==='plot-status');
    found = findSchema(found, project); // this one could have multiple schemas
    if(found?.schema) schema = found.schema;
    setPublicStatuses(schema);
  }, [schemas, project])

  useEffect(() => {
    if(!params.planId) {
      setActivePlan({});
    } else if(gis) {
      let found = gisPlans.find(x => x.appId===params.planId);
      if(!found) return alert('Error finding plan. Contact support.'); // lklklk
        planRef.current = found;
        setActivePlan(found);

     } else {
      (async () => {
        let plan = await db.plans.where({ appId: params.planId }).first();
        if(!plan) return alert('Error finding plan. Contact support.'); // lklklk
        planRef.current = plan;
        setActivePlan(plan);
      })();
    }
  }, [params.planId, gis])

  if(isLoading || loading) return <Loading />;

  return (
    <Fragment>
      { task?.name && (
        <Helmet>
          <title>{task.name} | {project.name} | {config.title}</title>
        </Helmet>
      )}
      <Outlet context={{ 
        online, 
        getTask, 
        task, 
        checklist,
        project, 
        phases,
        phasePlans,
        activePhase, 
        planGroups, 
        plans,
        activePlans,
        setActivePlans,
        activePlan,
        setActivePlan,
        planRef,
        libraries,
        symbols,
        types,
        typeVars,
        plots,
        setPlots,
        activePlots,
        activeQtys,
        activePlot,
        setActivePlot,
        publicStatuses,
        setPublicStatuses,
        mode,
        setMode,
        bounds,
        setBounds,
        search,
        setSearch,
        filters,
        setFilters
      }} />

      <Modal show={!emptyObj(activePlot)} fullscreen={true} onHide={handleClose} backdrop={false} keyboard={true} scrollable>
        <PlotEdit 
          obj={activePlot} 
          task={task}
          checklist={checklist}
          typeLists={typeLists}
          plotTasks={plotTasks}
          project={project} 
          activePhase={activePhase} 
          activePlan={activePlan}
          plans={plans}
          symbols={symbols}
          types={types} 
          typeVars={typeVars} 
          plots={plots} 
          setPlots={setPlots} 
          publicStatuses={publicStatuses}
          viewServer={viewServer}
          // photos={photos} 
          // setPhotos={setPhotos} 
          handleClose={handleClose} 
          // toParent={fromChild}
        />
      </Modal>
    </Fragment>
  )
}

export default View;
